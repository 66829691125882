.profile {
  width: 80%;
  max-width: 1200px;
  margin: auto;
&.main-info-container,
.acount-section,
.danger-zone {
  max-width: 90%;
  margin-block-end: 40px;
}

.icon-style {
  padding: 3px;
  background: #f8f8f8;
  border-radius: 100%;
}

.credentials-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 20px;
  border-block-end: 1px solid #eee;
  .credentials-box_text {
    h4 {
      margin: 0px;
      margin-block-end: 3px;
    }
    p {
      margin: 0px;
      color: #515151;
      font-size: 12.5px;
      display: inline-flex;
      gap: 5px;
      align-items: center;
    }
  }
}

.image-container{
    object-fit: contain;
    overflow: visible;
    border-radius: 100%;
    width: 80px;
    height: 80px;
}

.btn-container {
  display: flex;
  margin-top: 10px
}

}
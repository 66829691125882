/* Start Box */
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-grow-1 {
  flex-grow: 1;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-even {
  justify-content: space-evenly !important;
}

.justify-content-center {
  justify-content: center;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.d-inline-block{
  display: inline-block;
}

.m-0 {
  margin: 0px;
}

.mt-10px {
  margin-block-start: 10px !important;
}
.mt-50px {
  margin-block-start: 50px;
}

.my-5px {
  margin-block-start: 5px;
  margin-block-end: 5px;
}

.mb-5px {
  margin-block-end: 5px;
}

.mt-20px {
  margin-block-start: 20px;
}

.mt-30px {
  margin-block-start: 30px;
}

.mb-20px {
  margin-block-end: 20px !important;
}

.mt-120px {
  margin-block-start: 120px;
}

.mt-40px {
  margin-block-start: 40px !important;
}

.mb-50px {
  margin-block-end: 50px;
}
.mb-10 {
  margin-bottom: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-5 {
  margin-inline-end: 5px;
}

.ml-0 {
  margin-left: 0 !important;
}

.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 1rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.gap-2 {
  gap: 2px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-1-r {
  gap: 1rem;
}
/* End Box */

/* Start Hide */
.d-none {
  display: none;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

/* End Hide*/
.d-block {
  display: block;
}
.h-full {
  height: 100%;
}

.h-5 {
  height: 5rem;
}

.h-10 {
  height: 10rem;
}

.h-15 {
  height: 15rem;
}

.h-20 {
  height: 20rem;
}

.h-30 {
  height: 30rem;
}

.h-44px {
  height: 44px;
}

.w-full {
  width: 100%;
}
.w-50 {
  width: 50%;
}

.success {
  height: max-content;
  color: #50ba3f;
  background-color: rgb(107 191 119 / 33%);
  padding-inline: 3px;
  font-size: small;
}

.danger {
  height: max-content;
  color: #ef4444;
  padding-inline: 3px;
  background-color: rgba(191, 143, 107, 0.33);
}

.flex-equal {
  flex: 1;
}
.min-w-0 {
  min-width: 0;
}

.switch-input {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 4px;
  width: 50%;
  justify-content: space-between;
}

.mt-20 {
  margin-top: 22px;
}

.sxw-sticky {
  position: sticky;
  top: 0;
}

.sxw-divider {
  width: auto;
  height: 1px;
  background-color: #ececec;
  margin-block: 10px;
}

.text-hint {
  font-size: 0.9em;
  color: #999;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-width-button {
  display: block;
  width: 100%;
  button {
    justify-content: center;
    width: 100%;
    span {
      margin-right: 0.5rem !important;
    }
  }
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

.f-w-normal {
  font-weight: normal;
}

.color-gray {
  color: #646464;
}

.color-mirage {
  color: #22162b;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.text-align-start {
  text-align: start;
}

.text-align-center {
  text-align: center;
}

.text-align-end {
  text-align: end;
}

.input-2-col, .input-4-col {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  flex-wrap: wrap;
  .input-group {
    flex-grow: 1;
    label {
      display: block;
      margin-block-end: 7px;
    }
  }
}

.input-2-col > .input-group {
  flex-basis: 47%;
  width: 47%;
}

.input-4-col > .input-group {
  flex-basis: calc(25% - 20px);
  @media screen and (max-width: 800px) {
    flex-basis: calc(50% - 20px);
  }
  @media screen and (max-width: 600px) {
    flex-basis: 100%;
  }
}

.image-container,
.image-card-container {
  overflow: hidden;
  width: 70px;
  flex-shrink: 0;
  img{
    // height: auto;
    border-radius: 100%;
  }
}
.mt-20 {
  margin-top: 20px;
}

.ml-50 {
  margin-left: 50px;
}

.max-width {
  max-width: calc(42% - 15px);
}

.my-1 {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}

.mx-10px {
  margin-inline: 10px;
}

.hint {
  font-size: 12px;
  color: #797979
}

.mt-7{
  margin-top: 7px;
}

.ml-auto {
  margin-inline-start: auto;
}
.mr-auto {
  margin-inline-end: auto;
}

.p-10{
  padding: 10px;
}
.p-multiselect-filter-container {
  display: flex;
  order: 1;
}

.p-multiselect-close {
  order: 2;
}

.p-dropdown-items.p-scroller-content {
  width: 100%;
}

.p-multiselect-token-icon {
  margin-inline-start: 5px !important;
}

.p-multiselect-label-container {
  padding-right: 0 !important;
  padding-inline-end: 1.75rem !important;
}
.view-information-section {
  .title,.sub-title {
    text-transform: uppercase;
    color: #a4abb2;
    font-weight: 100;
    border-block-end: 1px solid #eef2f6;
    margin-block: 30px 25px;
    padding-block-end: 10px;
  }

  .sub-title {
    border-block-end: 0px;
    margin-block-end: 0;
  }

  p.label, .label {
    color: #000;
    font-weight: 200;

    span.value { 
      color: #22162b;
      padding-inline-start: 5px;
      font-weight: 700;
    }
  }
  p.label {
    margin: 12px 0;
  }
  .value { 
    color: #22162b;
    padding-inline-start: 5px;
    font-weight: 700;
  }
}

.info-content {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.info-title {
  font-size: 12px;
  padding-block: 10px;
  font-weight: 500;
  color: #A4ABB2;
  border-block-end: 1px solid #eee;
  margin-bottom: 10px;
}

.info-label {
  margin: 0;
  margin-inline-end: 8px !important;
  color: #000;
  font-size: 14px;
}
.info-value {
  margin: 0;
  font-size: 14px;
  color: #0a052b;
  font-weight: bold;
}


.input-theme {
  textarea {
    background-color: #f8f8f8 !important;
    border: 5pt !important;
    font-size: 15px !important;
    font-weight: normal;
    &:disabled,
    &.p-disabled,
    &[readonly] {
      background-color: #00000029;
      color: #0a052b;
      opacity: 1;
    }
    &::placeholder {
      color: #a8a8a8;
    }
  }
}

.input-theme {
  textarea {
    background-color: #f8f8f8;
    &:disabled,
    &.p-disabled,
    &[readonly] {
      background-color: #00000029;
      color: #0a052b;
      opacity: 1;
    }
  }
}

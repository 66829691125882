@font-face {
  font-family: 'Dg Sahabah';
  src: url('../fonts/sahabah/DG-Sahabah-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: Light;
  font-display: swap;
}

@font-face {
  font-family: 'Bahnschrift';
  src: url('../fonts/bahnschrift/bahnschrift.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  &[dir="rtl"] {
    font-family: 'Dg Sahabah', sans-serif !important;
    .p-component {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    .p-inputtext {
      font-family: 'Dg Sahabah', sans-serif !important;
      font-size: 15px !important;
    }
    input.search__input.ng-untouched.ng-pristine.ng-valid {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    button.footer__btn {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    button.map-btn {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    textarea#float-input {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
  }

  &:not([dir]) {
    font-family: 'Bahnschrift', sans-serif !important;
    .p-component {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    .p-inputtext {
      font-family: 'Bahnschrift', sans-serif !important;
      font-size: 15px !important;
    }
    input.search__input.ng-untouched.ng-pristine.ng-valid {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    button.footer__btn {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    button.map-btn {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    textarea#float-input {
      font-family: 'Bahnschrift', sans-serif !important;
    }
  }
}

:root {
  --icons-color: #242c42;
  --inputs-color: #f6f6f9;

  --page-title-space-start: 60px;
  --form-layout-space-start: 14px;
  --page-title-arrow-width: 16px;
  --page-title-arrow-space-end: 0.5rem;

  --dropdown-icon: url('/assets/images/icons/Polygon 8.svg');
  --filter-input-icon: url('/assets/images/icons/magnifying-glass.svg');
}

* {
  box-sizing: border-box;
}

body {
  font-weight: 500;
  font-size: .9rem;
  margin: 0 auto;
  color: #22162B;
  background-color: #FAFBFC;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #9c9ea5;
  background-color: #9c9ea5;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background-color: #e2e2e2;
  border-radius: 30px;
}

.filters-container {
  width: 100%;
  height: 60px;
  position: relative;
  z-index: 20;
  padding-top: 20px;
}

.filter-search {
  margin-left: 14px;
}

.stepper-container {
  width: calc(20% - 10px);
}

sxw-form {
  flex: 1;
  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-height: 0;
  /* ......................................................................................... */
  &>.form-container {
    height: calc(100% - 30px);
  }

  .step-content {
    height: 100% !important;
    /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
    min-height: 0;
    /* ......................................................................................... */
    display: flex;
    flex-direction: column;

    .content-container {
      height: 100%;
    }
  }

  div.form-buttons {
    padding: 10px 10px 5px 10px !important;
    button {
      background-color: #41BEB2;
      border: none;
      border-radius: 20px;
      margin-right: 20px;
      position: relative;
      height: 37px;
      width: 85px;
    }

    button.back-button {
      background-color: #ffffff;
      border: 1px solid #d2d7dd;
      padding-inline-start: 30px;
      color: black;
    }
    button.back-button::before {
      content: "\e931";
      padding-top: 1px;
      color: black;
      position: absolute;
      inset-inline-start: 5px;
      font-family: 'primeicons';
      line-height: 1;
      display: inline-block;
    }
    button.back-button:enabled:hover {
      background: none;
      color: black;
      border: 1px solid #d2d7dd;
    }

    button.next-button {
      background-color: #cfd8e3;
      border: 1px solid #cfd8e3;
      color: black;
    }
    button.next-button::after {
      content: "\e932";
      padding-top: 1px;
      color: black;
      position: absolute;
      right: 5px;
      font-family: 'primeicons';
      line-height: 1;
      display: inline-block;
    }
    button.next-button:enabled:hover {
      background: #cfd8e3;
      color: black;
      border: 1px solid #cfd8e3;
    }

    button.submit-button:enabled:hover {
      background: #41BEB2
    }
  }
}

.form-container {
  flex: 1;
  padding-top: 4px;
  overflow-x: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.form-layout {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  gap: 22px;

  &:not(.form-tabs-layout .form-layout) {
    padding: 0 0 0 var(--form-layout-space-start);
  }
}

.form-tabs-layout {
  padding: 0 0 0 var(--form-layout-space-start);

  .form-layout {
    height: 89%;
  }
}

sxw-page-title:not(.not-aligned) .path {
  margin-top: 20px;
}

sxw-page-title.not-aligned .path {
  padding-left: 0 !important;
}


.content {
  background-color: #ffffff;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-width: 0;
  /* ......................................................................................... */
}

.content .body-content {
  display: flex;
  flex-direction: column;
  //height: 100%;
  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-height: 0;
  /* ......................................................................................... */

  position: relative;
  padding: 0px 2%;
  height: 100%;
  @media screen and (max-width: 768px) {
    padding: 0;
  }

  & > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

  &::-webkit-scrollbar:horizontal {
    height: 0px;
  }
  }
}

.table-container {
  flex: 1;
  width: 100%;
  max-height: calc(100% - 253px);
  //max-height: calc(100% - 254px);
  height: auto;
  margin: 20px auto 0 4px;
  overflow-y: auto;

  &::-webkit-scrollbar:horizontal {
    height: 0px;
  }

  //&::-webkit-scrollbar:vertical {
  //  display: none;
  //}
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-left: -25px;
}
.footer {
  position: relative;
  width: 100%;
}

.listing-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  margin-block: 20px;
}

.listing-page-title {
  margin-inline-start: -20px;
}

.page-container {
  position: relative;
  height: 100%;
}

.p-datatable .p-datatable-wrapper {
  height: 100%;
  overflow: visible !important;
}
.status-active, .status-inactive, .status-pending, .status-accepted, .status-initiated, .status-initiatedExpired, .status-unpaidActive, .status-expired, .status-cancelled, .status-paused, .status-pastDue, .status-cancellationScheduledAt,
.status-upcoming {
  padding: 3px 16px;
  border-radius: 20px;
  color: white;
  font-size: .8rem;
  text-transform: uppercase;
  background-color: #3361E5;
}
.status-inactive {
  background-color: #E54F6D;
}
.status-pending {
  background-color: #515151;
}
.status-accepted {
  background-color: #3abf68;
}

.status-pastDue {
  background-color: rgb(202, 131, 0);
}

.status-initiated{
  background-color: #3498db;
}

.status-initiatedExpired{
  background-color: #e67e22;
}

.status-unpaidActive{
  background-color: #f1c40f;
}

.status-expired{
  background-color: black;
}

.status-cancellationScheduledAt {
  background-color: #95a5a6;
}
.status-cancelled {
  background-color: #E54F6D;
}

.status-paused {
  background-color: #9b59b6;
}

// TODO: review this.
app-update-operator,
app-create-operator,
app-update-driver,
app-create-driver {
  .time {
    .p-dropdown-trigger {
      background-image: none;
      .p-dropdown-trigger-icon {
        display: none;
      }
    }
    .p-dropdown {
      .p-dropdown-clear-icon {
        display: none;
      }
      height: 25px;
      width: 45px;
    }
    .p-dropdown-label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-left: 21px;
    }
  }
}

app-update-operator,
app-create-operator,
app-update-driver,
app-create-driver {
  .time {
    .p-dropdown-label{
      padding-right: 1.3rem !important;
    }
  }}
.linked-items {
  padding-inline-start: 30px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset-inline-start: 0%;
    inset-block-start: 29%;
    // transform: translateY(-50%);
    top: 5%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #000;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 5%;
    width: 2px;
    background-color: #000;
    z-index: 0;
    height: 100%;
  }
  &:last-child::after {
    display: none;
  }
}
.view-grid-layout .card {
  padding: 27px;
  border-radius: 20pt;
  box-shadow: 0 0 3px #00000040;
  max-width: 412px;
  background-color: #fff;
  line-height: 12px;
  margin-bottom: 30px;
  margin-inline-start: 2px;
  min-width: 412px;
  // min-width: max-content;
  // min-width: fit-content;
}

.header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 25px;
  gap: 15px;
}

.full-name {
  font-size: 22px;
  margin-block-start: 0px;
  margin-block-end: 5px;
  color: #0a052b;
}
.header-info {
  color: #515151;
  margin: 0;
  font-size: .8rem;
}

.links_container {
  display: flex;
  align-items: center;
  gap: 0px;
  margin-inline-start: 0px;
  padding-block-end: 15px;
  border-block-end: 1px solid #eee;

  a {
    padding: 10px 18px;
    border-radius: 20px;
    white-space: nowrap;

    &.active {
      background: #22162b;
      color: #fff;
    }
  }
}

.tabs {
  padding-inline-start: 40px;

  @media (max-width:1280px) {
    padding-inline-start: initial;
  }
}

.map-container {
  height: calc(100% - 25px);
  // margin-top: 25px;
  border-radius: 20px;
  overflow: hidden;
}

.view-grid-layout {
  display: grid;
  grid-template-columns: 27% 73%;
  margin-top: 10px;

  @media (max-width:1280px) {
    grid-template-columns: 1fr;
  }
}


@media screen and (max-width: 890px) {

  .stepper-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0 4%;
    overflow-x: auto;
    overflow-y: hidden;

  }
  .stepper-container::-webkit-scrollbar {
    width: 6px;
    // background-color: #3abf68;
    height: 0px;


    border: 2px solid #9c9ea5;
    background-color: #9c9ea5;
    border-radius: 30px;
  }

  .form-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: unset !important;
    height: unset !important;
  }

  .step-content {
    margin-top: 0 !important;
  }

  .steper ul {
    margin-bottom: 0;
    flex-direction: row;
  }


}


@media screen and (max-width: 850px){
  .view-grid-layout {
    display: block;
  }

  .view-grid-layout .card {
   max-width: 100%;
   padding: 20px;
   margin-bottom: 30px;
  }


  .links_container{
    overflow: auto;
  }


  .links_container::-webkit-scrollbar {
    height: 2px;
    border: 2px solid #9c9ea5;
    background-color: #9c9ea5;
  border-radius: 30px;
  }

}

.tab-filter-theme .card {
    padding: 0px;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    max-width: 100%;
    margin-bottom: 10px;
    line-height: 20px;
}

.rtl-theme {
  direction: rtl;
}

.rtl-theme{
  sxw-form{
    div.form-buttons {
      button.b-reverse::before {
        content: "\e932" !important;
      }
      button.n-reverse::after {
        content: "\e931" !important;
        right: unset;
        left: 5px;
      }
     }
    }
}

.rtl-theme{
.dropdown{
  .p-dropdown-clear-icon{
    right: unset !important;
    left: 40px;
  }
}
}

.rtl-theme{
  .custom-dropdown-panel{
    .p-dropdown-header .p-dropdown-filter-icon{
       right: unset !important;
       left: 2px;
     }
   } 
}


.rtl-theme{
  .custom-multi-dropdown{
  .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon{
  right:unset !important;
  left: 2px !important;
   }
  }
}

.rtl-theme p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon{
    right: unset !important;
    left: 40px !important;
}

.rtl-theme sxw-mobile-number .code-direction{
  left:unset !important;
  right: 10px !important;
  // unicode-bidi: bidi-override !important;
}


.rtl-theme sxw-reorder .item-reorder {
  &:not(:last-child):after {
    left: unset !important;
  }
    &-handle {
      cursor: grab;
      margin-right: 10px;
      display: flex;
      align-items: flex-start;
      padding-top: 31px;
      .default-handle {
        .order {
          margin-right: -40px !important;
        }
      }
    }
}

.rtl-theme sxw-reorder .order-reorder {
  right: -21px !important;
}
sxw-page-title {
  pointer-events: none;
}
.back-icon {
  pointer-events: auto;
}

// @media(max-width: 1000px){
//   .view-grid-layout .card {
  
//     min-width: 200px;
//   }
// }

@media (max-height: 500px) {
  .table-container {
    max-height: calc(100% - 50px);
    margin: 8px auto 0 4px;
  }
  .header{
    max-height: 10%;
    margin-bottom: 10px ;
  }
  .listing-footer{
    margin-block-end: 10px;
  }
}

.custom-table-container {
  max-height: calc(100vh - 454px) !important;
}

.view-tab-table-container {
  max-height: calc(100vh - 530px) !important;
  width: 54vw;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

@media (min-width: 769px) and (max-width: 1080px) {
  .create-button {
    display: flex !important;
  }
}

@media (max-width: 720px) {
  .create-button {
    width: 42px !important;
    height: 42px !important;
    border-radius: 50%;
    position: relative;
  }
  .btn.primary button {
    padding: 7px;
  }
  .create-button span, .btn.primary button span {
    display: none;
  }
  .btn.primary button sxw-svg-icon {
    margin-inline-end: 0 !important;
  }
}

.chat-status {
  padding: 2px 10px;
  text-transform: uppercase;
  color: #fff;
  background-color: darkgreen;
  border-radius: 10px;
  font-size: 10px;
  &.new {
    background-color: #e54f6d;
    color: #fff;
  }
  &.ongoing {
    background-color: #73a4bc;
    color: #fff;
  }
}



.rtl-theme .journey-details .pickup-station {
  &::after {
    content: "";
    background-image: url("/assets/images/icons/from-line.svg");
    inset-inline-start: 60%;
    transform: scaleX(-1); 
  }
}



.rtl-theme .journey-details .dropoff-station {
  &::before {
    content: "";
    background-image: url("/assets/images/icons/to-line.svg");
    inset-inline-start: -12%;
    transform: scaleX(-1); 
  }
}

.rtl-theme .details-icon img{
  transform: scaleX(-1); 
}




.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text{
  color: #242c42 !important;
}

.p-toast .p-toast-message.p-toast-message-error{
  border-left:solid #242c42 !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon){
  color: #242c42 !important;
}

.p-toast .p-toast-message.p-toast-message-error{
  background: #FFB7B8 !important;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close{
  color:#242c42 !important;
}
.sxw-tooltip {
  width: max-content !important;
  pointer-events: none;
}

.mobileDesign{
  display: block;
  direction: ltr;
}





